import { FaHome } from "react-icons/fa"
import { IconType } from "react-icons";
import { lazy } from "react";
import { MdFormatListNumbered, MdOutlineContentPasteSearch, MdOutlineDashboard, MdOutlineFactory, MdOutlineStickyNote2, MdOutlineStore, MdRequestQuote } from "react-icons/md";
import { RiCustomerService2Line, RiDeleteBinLine } from "react-icons/ri";
import { GoDownload } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import { TbTruckDelivery } from "react-icons/tb";

export const SignupPages = lazy(() => import("../../pages/SignupPages/SignupForm"))
export const SignupTypePages = lazy(() => import("../../pages/SignupPages/SignupSelectionScreen"));
export const SignupThankyouPages = lazy(() => import("../../pages/SignupPages/SignupWelcome"));
export const SigninPages = lazy(() => import("../../pages/SigninPages/SigninForm"))
export const SigninVerifyPages = lazy(() => import("../../pages/SigninPages/SigninVerifyForm"))
export const SigninSuccessfullyAsDealer = lazy(() => import("../../pages/SigninPages/SigninSuccessfulPage"))
export const AdminSigninPages = lazy(() => import("../../pages/SigninPages/AdminSigninFormPage"))
export const BrandsPage = lazy(() => import("../../pages/Brands/BrandsList"));
export const BrandViewPage = lazy(() => import("../../pages/Brands/BrandView"));
export const BusinessInformationPage = lazy(() => import("../../pages/Brands/BusinessInformation"));
export const ContactDetailsPage = lazy(() => import("../../pages/Brands/ContactDetails"));
export const CoreBusinessDetailsPage = lazy(() => import("../../pages/Brands/CoreBusinessDetails"));
export const SegmentDetailsPage = lazy(() => import("../../pages/Brands/SegmentDetails"));
export const DocumentsPage = lazy(() => import("../../pages/Brands/Documents"));
export const ThankyouPage = lazy(() => import("../../pages/Brands/Thankyou"));
export const ListServicePage = lazy(() => import("../../pages/Brands/ListService"));
export const ViewListServicePage = lazy(() => import("../../pages/Brands/ViewServiceAreaList"));
export const ExclusiveDealerPage = lazy(() => import("../../pages/Brands/ExclusiveDealers"));
export const BrandOrdersList = lazy(() => import("../../pages/Brands/BrandOrdersList"))
export const MultiGstBrandPage = lazy(() => import("../../pages/Brands/MultiGstBrand"));
export const ConfigurationPage = lazy(() => import("../../pages/Configuration/ConfigurationList"));
export const CataloguePage = lazy(() => import("../../pages/Configuration/CatalogueList"));
export const ConfigurationMetricsPage = lazy(() => import("../../pages/Configuration/ConfigurationMetrics"));
export const ConfigurationHSNPage = lazy(() => import("../../pages/Configuration/HSN"));
export const AddConfigurationPage = lazy(() => import("../../pages/Configuration/AddConfiguration"));
export const AddCatalogue = lazy(() => import("../../pages/Configuration/AddCataloguePage"));
export const ProfileUpdateSetting = lazy(() => import("../../pages/Log/LogActivityTab"));
export const CatalogueListViewPage = lazy(() => import("../../pages/Configuration/CatalogueListViewPage"));
export const CatalogueEligiblePage = lazy(() => import("../../components/organisms/ConfigurationOrganism/CatalogueEligibleDealerOrganism"));
export const LoyaltyListPage = lazy(() => import("../../pages/Loyalty/LoyaltyList"));
export const AddLoyaltyPage = lazy(() => import("../../pages/Loyalty/AddLoyalty"));
export const CustomSpecPage = lazy(() => import("../../pages/Configuration/CustomSpec"));
export const ProductDetailsPage = lazy(() => import("../../pages/AddProduct/ProductDetails"));
export const JogglePointPage = lazy(() => import("../../pages/Loyalty/JogglePointList"));
export const AddJogglePointPage = lazy(() => import("../../pages/Loyalty/AddJogglePoint"));
export const BannerImageListPage = lazy(() => import("../../pages/Loyalty/BannerImageList"));
export const AddBannerImagePage = lazy(() => import("../../pages/Loyalty/AddBannerImage"));
export const AddLoyaltyProgramPage = lazy(() => import("../../pages/LoyaltyProgram/AddLoyaltyProgram"));
export const DealerPage = lazy(() => import("../../pages/Dealer/DealerList"));
export const DealerViewPage = lazy(() => import("../../pages/Dealer/DealerView"));
export const MediaDetailsPage = lazy(() => import("../../pages/AddProduct/MediaDetails"));
export const DescriptionDetailsPage = lazy(() => import("../../pages/AddProduct/DescriptionDetails"));
export const PricingDetailsPage = lazy(() => import("../../pages/AddProduct/PricingDetails"));
export const ViewProductDetailsPage = lazy(() => import("../../pages/AddProduct/ViewProductDetails"));
export const DetailedViewProductDetailsPage = lazy(() => import("../../pages/AddProduct/DetailedViewProductDetails"));
export const LoyaltyProgramListPage = lazy(() => import("../../pages/LoyaltyProgram/LoyaltyProgramList"));
export const AdhocProgramListPage = lazy(() => import("../../pages/LoyaltyProgram/AdhocProgramList"));
export const OrdersTapListPage = lazy(() => import("../../pages/Dealer/OrdersTapList"));
export const OverviewPage = lazy(() => import("../../pages/Dealer/Overview"));
export const OrdersListPage = lazy(() => import("../../pages/Dealer/OrdersList"));
export const RaisedTicketsPage = lazy(() => import("../../pages/Dealer/RaisedTickets"));
export const PricingUpdatePage = lazy(() => import("../../pages/Dealer/PricingUpdate"));
export const SamplePriceUpdate = lazy(() => import("../../pages/Dealer/SamplePriceUpdate"));
export const TransactionsPage = lazy(() => import("../../pages/Dealer/Transactions"));
export const QuotesPage = lazy(() => import("../../pages/Dealer/Quotes"));
export const ActivityLogsPage = lazy(() => import("../../pages/Dealer/ActivityLogs"));
export const QuoteActivityPage = lazy(() => import("../../pages/Dealer/QuoteActivity"));
export const OrderActivityPage = lazy(() => import("../../pages/Dealer/OrderActivity"));
export const JRewardsActivityPage = lazy(() => import("../../pages/Dealer/JRewards"));
export const ProductUpdateActivityPage = lazy(() => import("../../pages/Dealer/ProfileUpdate"));
export const OrdersLoyaltyPage = lazy(() => import("../../pages/Dealer/Loyalty"));
export const ViewOrderPage = lazy(() => import("../../pages/Dealer/ViewOrder"));
export const DealerOverviewEditUpdatePage = lazy(() => import("../../pages/Dealer/DealerOverviewEditPage"));
export const DetailedQuotesPage = lazy(() => import("../../components/templates/DealerTemplate/DetailedQuotesTemplate"))
export const DealerSalesOrderDetailsViewPageTap = lazy(() => import("../../pages/Dealer/OrdersList/DealerSalesOrderDetailsViewPageTap"))
export const DistributorSalesOrderDetailsViewPageTap = lazy(() => import("../../pages/Distributors/DistributorOrdersList/DistributorSalesOrderViewPageTab"))
export const AdhocListPage = lazy(() => import("../../pages/LoyaltyProgram/AdhocList"));
export const AdhocListTemplate = lazy(() => import("../../components/templates/LoyaltyProgramTemplate/AdhocListTemplate"));
export const LoyaltyProductPage = lazy(() => import("../../pages/AddProduct/LoyaltyProduct"));
export const OrdersPage = lazy(() => import("../../pages/OrdersPage/OrderListTap"))
export const OrdersFromEndUserListDetailsPage = lazy(() => import("../../pages/OrdersPage/OrdersFromEndUser/OrdersFromEndUserListDetailsPage"))
export const OrdersListDetailsPage = lazy(() => import("../../pages/OrdersPage/OrderListDetailsViewPage"))
export const BrandOrdersListView = lazy(() => import("../../pages/Brands/BrandOrdersList/BrandOrdersListViewPage"));
export const BrandQuotesListPage = lazy(() => import("../../pages/Brands/BrandQuotesListPage"));
export const ProductActivityPage = lazy(() => import("../../pages/Brands/ProductActivity"));
export const OrderStatusActivityPage = lazy(() => import("../../pages/Brands/OrderStatusActivity"));
export const ServiceAreaActivityPage = lazy(() => import("../../pages/Brands/ServiceAreaActivity"));
export const PricingRuleActivityPage = lazy(() => import("../../pages/Brands/PricingRuleActivity"));
export const LoyaltyProgramActivityPage = lazy(() => import("../../pages/Brands/LoyaltyProgramActivity"));
export const ExclusivedDealerActivityPage = lazy(() => import("../../pages/Brands/ExclusiveDealerActivity"));
export const BrandQuotesDetailedPage = lazy(() => import("../../components/templates/BrandsTemplate/BrandQuotesListDetailedviewPageTemplate"))
export const RedeemedListPage = lazy(() => import("../../pages/LoyaltyProgram/RedeemedList"))
export const AdminQuotesPage = lazy(() => import("../../pages/QuotesPage/QuotesListTap"))
export const AdminQuotesDetailedViewPage = lazy(() => import("../../components/templates/QuotesPageTemplate/QuotesDetailedviewPageTemplate"))
export const KBPage = lazy(() => import("../../pages/ForKBPage"))
export const PurgeBrandPage = lazy(() => import("../../pages/Purge/PurgeBrand"))
export const PurgeDealerPage = lazy(() => import("../../pages/Purge/PurgeDealer"))
export const PurgeProductPage = lazy(() => import("../../pages/Purge/PurgeProduct"))
export const PurgeServiceAreaPage = lazy(() => import("../../pages/Purge/PurgeServiceArea"))
export const PurgeOrderPage = lazy(() => import("../../pages/Purge/PurgeOrder"))
export const PurgeCategoryPage = lazy(() => import("../../pages/Purge/PurgeCategory"))
export const PurgeCataloguePage = lazy(() => import("../../pages/Purge/PurgeCatalogue"))
export const PurgeQuotePage = lazy(() => import("../../pages/Purge/PurgeQuote"))
export const PurgeLoyaltyPage = lazy(() => import("../../pages/Purge/PurgeLoyalty"))
export const PurgeAdhocPage = lazy(() => import("../../pages/Purge/PurgeAdhoc"))
export const ReferralProgramListPage = lazy(() => import("../../pages/LoyaltyProgram/ReferralProgramList"))
export const AddReferralProgramPage = lazy(() => import("../../pages/LoyaltyProgram/AddReferralProgram"))
export const ReportCenterPage = lazy(() => import("../../pages/DownloadCenter/ReportCenter"))
export const DealerDownloadPage = lazy(() => import("../../pages/DownloadCenter/DealerDownload"))
export const CategoryActivityPage = lazy(() => import("../../pages/Configuration/CategoryActivity"))
export const CatalogueActivityPage = lazy(() => import("../../pages/Configuration/CatalogueActivity"))
export const MetricsActivityPage = lazy(() => import("../../pages/Configuration/MetricsActivity"))
export const JogglePointsActivityPage = lazy(() => import("../../pages/Configuration/JogglePointsActivity"))
export const CustomerListPage = lazy(() => import("../../pages/Customers/CustomerList"));
export const CustomerViewPage = lazy(() => import("../../pages/Customers/CustomerView"));
export const CustomerInfoPage = lazy(() => import("../../pages/Customers/CustomerInfo"));
export const CustomerThankYouPage = lazy(() => import("../../pages/Customers/CustomerThankYou"));
export const CustomerSiteThankyouPage = lazy(() => import("../../pages/Customers/CustomerSiteList/CustomerThankyou"));
export const CustomerOrderListPage = lazy(() => import("../../pages/Customers/CustomerOrderList"));
export const CustomerOrderViewPage = lazy(() => import("../../pages/Customers/CustomerOrderView"));
export const CustomerOrderCartPage = lazy(() => import("../../pages/Customers/CustomerOrderCart"));
export const CustomerOrderSummaryPage = lazy(() => import("../../pages/Customers/CustomerOrderSummary"));
export const CustomerOrderPaymentPage = lazy(() => import("../../pages/Customers/CustomerOrderPayment"));
export const CustomerOrderProceedToBuyPage = lazy(() => import("../../pages/Customers/CustomerOrderProceedToBuy"));
export const CustomerOrderApprovalCustomerPage = lazy(() => import("../../pages/Customers/CustomerOrderApprovalCustomer"));
export const CustomerOrderSaveDraftPage = lazy(() => import("../../pages/Customers/CustomerOrderSaveDraft"));
export const CustomerOrderProductDetailsPage = lazy(() => import("../../pages/Customers/CustomerOrderProductDetails"));
export const CustomerSiteListPage = lazy(() => import("../../pages/Customers/CustomerSiteList"));
export const CustomerSiteViewPage = lazy(() => import("../../pages/Customers/CustomerSiteView"));
export const CustomerEnquiryListPage = lazy(() => import("../../pages/Customers/CustomerEnquiryList"));
export const CustomerQuotationsViewPage = lazy(() => import("../../pages/Customers/CustomerQuotationsView"));
export const CustomerTicketListPage = lazy(() => import("../../pages/Customers/CustomerTicketList"));
export const CustomerTicketViewPage = lazy(() => import("../../pages/Customers/CustomerTicketView"));
export const CustomerTicketNewPage = lazy(() => import("../../pages/Customers/CustomerTicketNew"));
export const CustomerTransactionPage = lazy(() => import("../../pages/Customers/CustomerTransaction"));
export const CustomerActivityLogPage = lazy(() => import("../../pages/Customers/CustomerActivityLog"));
export const CustomerAddNewSitePage = lazy(() => import("../../pages/Customers/CustomerSiteList/CustomerAddNewSite"));
export const CustomerSiteSOCOrderViewPage = lazy(() => import("../../pages/Customers/CustomerSiteView/CustomerOrderViewBasedonStage"));
export const AddProductPurchasedInOffline = lazy(() => import("../../pages/Customers/CustomerSiteView/AddProductPurchasedInOffline"));
export const GenerateDealerCodePage = lazy(() => import("../../pages/Dealer/GenerateDealerCode"));
export const ReferralRedeemedListPage = lazy(() => import("../../pages/LoyaltyProgram/ReferralRedeemedList"))
export const DistributorPage = lazy(() => import("../../pages/Distributors/DistributorList"));
export const DistributorViewPage = lazy(() => import("../../pages/Distributors/DistributorView"));
export const DistributorBusinessInformationPage = lazy(() => import("../../pages/Distributors/DistributorBusinessInformation"));
export const DistributorMultiGstPage = lazy(() => import("../../pages/Distributors/DistributorMultiGst"));
export const DistributorContactDetailsPage = lazy(() => import("../../pages/Distributors/DistributorContactDetails"));
export const DistributorCoreBusinessDetailsPage = lazy(() => import("../../pages/Distributors/DistributorCoreBusinessDetails"));
export const DistributorSegmentDetailsPage = lazy(() => import("../../pages/Distributors/DistributorSegmentDetails"));
export const DistributorDocumentsPage = lazy(() => import("../../pages/Distributors/DistributorDocuments"));
export const DistributorThankyouPage = lazy(() => import("../../pages/Distributors/DistributorThankyou"));
export const DistributorListServicePage = lazy(() => import("../../pages/Distributors/DistributorListService"));
export const DistributorViewListServicePage = lazy(() => import("../../pages/Distributors/DistributorViewServiceAreaList"));
export const DistributorExclusiveDealerPage = lazy(() => import("../../pages/Distributors/DistributorExclusiveDealers"));
export const DistributorOrdersList = lazy(() => import("../../pages/Distributors/DistributorOrdersList"))
export const DistributorOrdersListView = lazy(() => import("../../pages/Distributors/DistributorOrdersListViewPage"));
export const DistributorQuotesListPage = lazy(() => import("../../pages/Distributors/DistributorQuotesListPage"));
export const DistributorQuotesDetailedPage = lazy(() => import("../../components/templates/DistributorsTemplate/DistributorQuotesListDetailedviewPageTemplate"));
export const DistributorBrandListPage = lazy(() => import("../../pages/Distributors/DistributorBrandList"));
export const BrandDistributorListPage = lazy(() => import("../../pages/Brands/BrandDistributorList"));
export const DistributorProductListPage = lazy(() => import("../../pages/Distributors/DistributorProductList"));
export const DistributorProductViewPage = lazy(() => import("../../pages/Distributors/DistributorProductView"));
// export const BrandQuotesDetailedPage = lazy(() => import("../../components/templates/BrandsTemplate/BrandQuotesListDetailedviewPageTemplate"))
export const DeeplinkPage = lazy(() => import("../../pages/Deeplink"))

const RoutesList: {
    path: any[];
    element?: React.LazyExoticComponent<() => JSX.Element>;
    name?: string | undefined;
    icon?: IconType | undefined;
    highlight?: string[];
}[] = [
        {
            path: ["/", "/admin_signin"],
            element: AdminSigninPages
        },
        {
            path: ["/user_signup", "/dealer_signup"],
            element: SignupPages
        },
        {
            path: ["/signup_thank"],
            element: SignupThankyouPages
        },
        {
            path: ["/brand_signin", "/user_signin", "/dealer_signin"],
            element: SigninPages
        },
        {
            path: ["/verify_signin"],
            element: SigninVerifyPages
        },
        {
            path: ["/signin_successful"],
            element: SigninSuccessfullyAsDealer,
        },
        {
            path: ["/dashboard"],
            element: BrandsPage,
            name: "Dashboard",
            icon: MdOutlineDashboard,
            highlight: ["/dashboard"],
        },
        {
            path: [process.env.REACT_APP_TICKETS],
            name: "Tickets",
            icon: MdOutlineStickyNote2,
        },
        {
            path: ["/orders/order_from_dealer", "/orders/order_from_customer"],
            element: OrdersPage,
            name: "Orders",
            icon: MdFormatListNumbered,
            highlight: ["/orders"],
        },
        {
            path: ["/orders/order_from_customer/:order_id"],
            element: OrdersFromEndUserListDetailsPage,
            highlight: ["/orders"],
        },

        {
            path: ["/quotes/quotes_to_dealer","/quotes/enquiry_from_customer"],
            element: AdminQuotesPage,
            name: "Quotes",
            icon: MdRequestQuote,
            highlight: ["/quotes"],
        },
        {
            path: ["/quotes/view"],
            element: AdminQuotesDetailedViewPage,
            highlight: ["/orders"],
        },
        {
            path: ["/orders/view_details"],
            element: OrdersListDetailsPage,
            highlight: ["/orders"],
        },
        {
            path: ["/brands"],
            element: BrandsPage,
            name: "Brands",
            icon: MdOutlineFactory,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/brand_view", "/brands/brand_view/:brand_id"],
            element: BrandViewPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/add_brands", "/brands/add_brands/:edit_business_information"],
            element: BusinessInformationPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/contact_details", "/brands/contact_details/:edit_contact_details"],
            element: ContactDetailsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/multi_gst_brand", "/brands/multi_gst_brand/:edit_multi_gst_brand"],
            element: MultiGstBrandPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/core_business_details", "/brands/core_business_details/:edit_core_business_details"],
            element: CoreBusinessDetailsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/segment_details", "/brands/segment_details/:edit_segment_details"],
            element: SegmentDetailsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/documents", "/brands/documents/:edit_documents"],
            element: DocumentsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/list_service"],
            element: ListServicePage,
            highlight: ["/brands/list_service"],
        },
        {
            path: ["/brands/servicearea_list"],
            element: ViewListServicePage,
            highlight: ["/brands/servicearea_list"],
        },
        {
            path: ["/brands/exclusive_dealers"],
            element: ExclusiveDealerPage,
            highlight: ["/brands/exclusive_dealers"],
        },
        {
            path: ["/brands/loyalty_program"],
            element: LoyaltyProgramListPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/adhoc_program"],
            element: AdhocProgramListPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/adhoc_list", "/brands/adhoc_list/:adhoc_edit_id"],
            element: AdhocListPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/add_loyalty_program", "/brands/add_loyalty_program/:loyalty_edit_id"],
            element: AddLoyaltyProgramPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/referral_program"],
            element: ReferralProgramListPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/add_referral_program", "/brands/add_referral_program/:referral_edit_id"],
            element: AddReferralProgramPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/referral_redeemed_list", "/brands/referral_redeemed_list/:referral_redeemed_id"],
            element: ReferralRedeemedListPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/orders_list"],
            element: BrandOrdersList,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/orders_list_view"],
            element: BrandOrdersListView,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/quotes"],
            element: BrandQuotesListPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/quotes/view"],
            element: BrandQuotesDetailedPage,
            highlight: ["/brands"]
        },
        {
            path: ["/brands/product_activity"],
            element: ProductActivityPage,
            highlight: ["/brands"]
        },
        {
            path: ["/brands/orderStatus_activity"],
            element: OrderStatusActivityPage,
            highlight: ["/brands"]
        },
        {
            path: ["/brands/serviceArea_activity"],
            element: ServiceAreaActivityPage,
            highlight: ["/brands"]
        },
        {
            path: ["/brands/pricingRules_activity"],
            element: PricingRuleActivityPage,
            highlight: ["/brands"]
        },
        {
            path: ["/brands/loyalty_activity"],
            element: LoyaltyProgramActivityPage,
            highlight: ["/brands"]
        },
        {
            path: ["/brands/exclusive_dealer_activity"],
            element: ExclusivedDealerActivityPage,
            highlight: ["/brands"]
        },
        {
            path: ["/brands/distributor_list"],
            element: BrandDistributorListPage,
            highlight: ["/brands"]
        },
        {
            path: ["/dealer"],
            element: DealerPage,
            name: "Dealers",
            icon: MdOutlineStore,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/dealer_orders", "/dealer/dealer_sales_order"],
            element: OrdersListPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/dealer_quotes"],
            element: QuotesPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/dealer_raisedtickets"],
            element: RaisedTicketsPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/dealer_pricing_update"],
            element: PricingUpdatePage,
            highlight: ["/dealer"],
        },
        {
            path: ["/sample_pricing_update"],
            element: SamplePriceUpdate,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/dealer_transaction"],
            element: TransactionsPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/dealer_activity"],
            element: ActivityLogsPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/quote_activity"],
            element: QuoteActivityPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/order_activity"],
            element: OrderActivityPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/jrewards_activity"],
            element: JRewardsActivityPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/profile_update"],
            element: ProductUpdateActivityPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/dealer_loyalty"],
            element: OrdersLoyaltyPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/dealer_overview"],
            element: DealerViewPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/view_order"],
            element: ViewOrderPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/view_quotes"],
            element: DetailedQuotesPage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/edit_update"],
            element: DealerOverviewEditUpdatePage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer/generate_code"],
            element: GenerateDealerCodePage,
            highlight: ["/dealer"],
        },
        {
            path: ["/dealer_sales_order/product_details/:order_id", "/dealer_sales_orders/financial_information/:order_id", "/dealer_sales_orders/shipping_logistics/:order_id", "/dealer_sales_orders/interaction_history/:order_id", "/dealer_sales_orders/payments/:order_id", "/dealer_sales_orders/order_docs/:order_id"],
            element: DealerSalesOrderDetailsViewPageTap,
            highlight: ["/dealer"],
        },
        {
            path: ["/distributor_sales_order/product_details", "/distributor_sales_orders/financial_information/:order_id", "/distributor_sales_orders/shipping_logistics/:order_id", "/distributor_sales_orders/interaction_history/:order_id", "/distributor_sales_orders/payments/:order_id", "/distributor_sales_orders/order_docs/:order_id"],
            element: DistributorSalesOrderDetailsViewPageTap,
            highlight: ["/distributors"],
        },
        {
            path: ["/customers"],
            element: CustomerListPage,
            name: "Customers",
            icon: FiUsers,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_view"],
            element: CustomerViewPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_info", "/customers/customer_info/:customer_id"],
            element: CustomerInfoPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_thankyou"],
            element: CustomerThankYouPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/thankyou"],
            element: CustomerSiteThankyouPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_order_list"],
            element: CustomerOrderListPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_order_view/:order_id"],
            element: CustomerOrderViewPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_order_cart",],
            element: CustomerOrderCartPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_order_summary"],
            element: CustomerOrderSummaryPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_order_payment"],
            element: CustomerOrderPaymentPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_order_proceedtobuy"],
            element: CustomerOrderProceedToBuyPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_order_approvalcustomer"],
            element: CustomerOrderApprovalCustomerPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_order_savedraft"],
            element: CustomerOrderSaveDraftPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_product_details"],
            element: CustomerOrderProductDetailsPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_site_list"],
            element: CustomerSiteListPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customers_site_view/:site_id"],
            element: CustomerSiteViewPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customer_site/soc_order_view/:order_id"],
            element: CustomerSiteSOCOrderViewPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_enquiry_list"],
            element: CustomerEnquiryListPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_quotations_view"],
            element: CustomerQuotationsViewPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_ticket_list"],
            element: CustomerTicketListPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_ticket_view"],
            element: CustomerTicketViewPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_ticket_new"],
            element: CustomerTicketNewPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_transaction"],
            element: CustomerTransactionPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/customer_activity_log"],
            element: CustomerActivityLogPage,
            highlight: ["/customers"],
        },
        {
            path: ["/customers/add_new_site", "/customers/add_new_site/:siteId"],
            element: CustomerAddNewSitePage,
            highlight: ["/customers"],
        },
        // {
        //     path: ["/customers/add_new_site_inventory"],
        //     element: CustomerAddNewSiteInventoryPage,
        //     highlight: ["/customers"],
        // },
        {
            path: ["/customers/customer_site/add_product_purchased_in_offline"],
            element: AddProductPurchasedInOffline,
            highlight: ["/customers"],
        },
        {
            path: ["/distributors"],
            element: DistributorPage,
            name: "Distributors",
            icon: TbTruckDelivery,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/distributor_view", "/distributors/distributor_view/:distributor_id"],
            element: DistributorViewPage,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/add_distributor", "/distributors/add_distributor/:edit_distributor_business_information"],
            element: DistributorBusinessInformationPage,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/distributor_multi_gst", "/distributors/distributor_multi_gst/:distributor_multi_gst"],
            element: DistributorMultiGstPage,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/distributor_contact_details", "/distributors/distributor_contact_details/:edit_distributor_contact_details"],
            element: DistributorContactDetailsPage,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/distributor_core_business_details", "/distributors/distributor_core_business_details/:edit_distributor_core_business_details"],
            element: DistributorCoreBusinessDetailsPage,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/distributor_segment_details", "/distributors/distributor_segment_details/:edit_distributor_segment_details"],
            element: DistributorSegmentDetailsPage,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/distributor_documents", "/distributors/distributor_documents/:edit_distributor_documents"],
            element: DistributorDocumentsPage,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/distributor_list_service"],
            element: DistributorListServicePage,
            highlight: ["/distributors/distributor_list_service"],
        },
        {
            path: ["/distributors/distributor_servicearea_list"],
            element: DistributorViewListServicePage,
            highlight: ["/distributors/distributor_servicearea_list"],
        },
        {
            path: ["/distributors/distributor_thankyou"],
            element: DistributorThankyouPage,
            highlight: ["/distributors"]
        },
        {
            path: ["/distributors/distributor_exclusive_dealers"],
            element: DistributorExclusiveDealerPage,
            highlight: ["/distributors/distributor_exclusive_dealers"],
        },
        {
            path: ["/distributors/distributor_sales_order"],
            element: DistributorOrdersList,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/distributor_orders_list_view"],
            element: DistributorOrdersListView,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/distributor_quotes"],
            element: DistributorQuotesListPage,
            highlight: ["/distributors"],
        },
        // {
        //     path: ["/distributor/distributor_orders", "/distributor/distributor_sales_order"],
        //     element: DistributorOrdersListPage,
        //     highlight: ["/distributors"],
        // },
        {
            path: ["/distributors/distributor_quotes/view"],
            element: DistributorQuotesDetailedPage,
            highlight: ["/distributors"]
        },
        {
            path: ["/distributors/brand_list", "/distributors/brand_list/:distributor_id"],
            element: DistributorBrandListPage,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/product_list", "/distributors/product_list/:brand_id"],
            element: DistributorProductListPage,
            highlight: ["/distributors"],
        },
        {
            path: ["/distributors/product_view", "/distributors/product_view/:distributor_id"],
            element: DistributorProductViewPage,
            highlight: ["/distributors"],
        },
        {
            path: [process.env.REACT_APP_INSIGHTS],
            name: "Insights",
            icon: FaHome,
        },
        {
            path: [process.env.REACT_APP_COMMUNICATIONS],
            name: "Communication",
            icon: RiCustomerService2Line,
        },
        {
            path: ["/configuration/configuration_category"],
            element: ConfigurationPage,
            name: "Configuration",
            icon: MdOutlineContentPasteSearch,
            highlight: ["/configuration"],
        },
        {
            path: ["/configuration/add_configuration"],
            element: AddConfigurationPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/configuration/configuration_catalogue"],
            element: CataloguePage,
            highlight: ["/configuration"],
        },
        {
            path: ["/settings/profile_update_setting"],
            element: ProfileUpdateSetting,
            highlight: ["/setting"],
        },
        {
            path: ["/configuration/configuration_metrics"],
            element: ConfigurationMetricsPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/configuration/hsn_code"],
            element: ConfigurationHSNPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/configuration/add_catalogue", "/configuration/add_catalogue/:catalogue_id"],
            element: AddCatalogue,
            highlight: ["/configuration"],
        },
        {
            path: ["/configuration/view_catalogue", "/configuration/view_catalogue/:eligible_dealer"],
            element: CatalogueListViewPage,
            highlight: ["/configuration"],
        },
        // {
        //     path: ["/loyalty"],
        //     element: LoyaltyListPage,
        //     name: "Manage Loyalty",
        //     icon: MdOutlineCardGiftcard,
        //     highlight: ["/loyalty"],
        // },
        {
            path: ["/purge_brand"],
            element: PurgeBrandPage,
            name: "Purge",
            icon: RiDeleteBinLine,
            highlight: ["/purge"],
        },
        {
            path: ["/purge_dealer"],
            element: PurgeDealerPage,
            highlight: ["/purge"],
        },
        {
            path: ["/purge_product"],
            element: PurgeProductPage,
            highlight: ["/purge"],
        },
        {
            path: ["/purge_servicearea"],
            element: PurgeServiceAreaPage,
            highlight: ["/purge"],
        },
        {
            path: ["/purge_order"],
            element: PurgeOrderPage,
            highlight: ["/purge"],
        },
        {
            path: ["/purge_category"],
            element: PurgeCategoryPage,
            highlight: ["/purge"],
        },
        {
            path: ["/purge_catalogue"],
            element: PurgeCataloguePage,
            highlight: ["/purge"],
        },
        {
            path: ["/purge_quote"],
            element: PurgeQuotePage,
            highlight: ["/purge"],
        },
        {
            path: ["/purge_loyalty"],
            element: PurgeLoyaltyPage,
            highlight: ["/purge"],
        },
        {
            path: ["/purge_adhoc"],
            element: PurgeAdhocPage,
            highlight: ["/purge"],
        },
        {
            path: ["/loyalty/add_loyalty"],
            element: AddLoyaltyPage,
            highlight: ["/loyalty"],
        },
        {
            path: ["/configuration/joggle_point"],
            element: JogglePointPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/configuration/add_joggle_point"],
            element: AddJogglePointPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/loyalty/banner_image"],
            element: BannerImageListPage,
            highlight: ["/loyalty"],
        },
        {
            path: ["/loyalty/add_banner_image"],
            element: AddBannerImagePage,
            highlight: ["/loyalty"],
        },
        // {
        //     path: ["/users"],
        //     element: BrandsPage,
        //     name: "Users",
        //     icon: MdOutlinePersonAddAlt,
        //     highlight: ["/users"],
        // },
        // {
        //     path: ["/settings"],
        //     element: BrandsPage,
        //     name: "Settings",
        //     icon: MdOutlineSettings,
        //     highlight: ["/settings"],
        // },
        {
            path: ["/brands/thankyou"],
            element: ThankyouPage,
            highlight: ["/brands"]
        },
        {
            path: ["/configuration/custom-spec"],
            element: CustomSpecPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/brands/product-details", "/brands/product-details/:product_edit_id"],
            element: ProductDetailsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/add-media"],
            element: MediaDetailsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/description"],
            element: DescriptionDetailsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/pricing-details"],
            element: PricingDetailsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/product-view-details"],
            element: ViewProductDetailsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/detailed-list-view-product"],
            element: DetailedViewProductDetailsPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/add_product/loyalty"],
            element: LoyaltyProductPage,
            highlight: ["/brands"],
        },
        {
            path: ["/brands/redeemed_list", "/brands/redeemed_list/:loyalty_redeemed_id"],
            element: RedeemedListPage,
            highlight: ["/brands"],
        },
        {
            path: ["/web_kb"],
            element: KBPage,
            highlight: ["/brands"],
        },
        {
            path: ["/report_center"],
            element: ReportCenterPage,
            name: "Download Center",
            icon: GoDownload,
            highlight: ["/download_center"],
        },
        {
            path: ["/dealer_download"],
            element: DealerDownloadPage,
            highlight: ["/download_center"],
        },
        {
            path: ["/configuration/configuration_activity_category"],
            element: CategoryActivityPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/configuration/catalogue_activity_catalogue"],
            element: CatalogueActivityPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/configuration/metrics_activity_metrics"],
            element: MetricsActivityPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/configuration/joggle_points_activity"],
            element: JogglePointsActivityPage,
            highlight: ["/configuration"],
        },
        {
            path: ["/referring", "/deep_link_for_referring", "/signinverified", "/signupverified"],
            element: DeeplinkPage,
        },
    ]

export default RoutesList;