import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { district_id?: number, redefined_data?: { text: string, value: string }[] } = {}

export const PincodeListSlice = createSlice({
    name: "PincodeListSlice",
    initialState: initialState,
    reducers: {
        loadPincodeList: (state, action: PayloadAction<any>) => {
            state.district_id = action?.payload?.data?.district_id as number ?? undefined;
            state.redefined_data = action?.payload?.data?.map((pin: any) => ({
                text: pin.pincode?.toString(),
                value: pin.pincodeid,
            }));
        },
    },
});

export const { loadPincodeList } = PincodeListSlice.actions
export default PincodeListSlice.reducer